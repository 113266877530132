import * as React from "react";

function CounterFunction() {
  const [counter, setCounter] = React.useState(10);

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  React.useEffect(() => {
    if (counter === 0) {
      var url = 'https://simcaq.ufg.br/';
      window.location = url;
      setCounter(10);
    }
  },)

  return (
    <span>{counter}</span>
  );
}

export default CounterFunction;